import { useEffect } from 'react'

export const useDisableScroll = (condition: boolean) => {
  useEffect(() => {
    const html = document.querySelector('html')
    const body = document.querySelector('body')

    if (condition) {
      html?.classList.add('overflow-hidden', 'h-screen')
      body?.classList.add('overflow-hidden', 'h-screen')
    } else {
      html?.classList.remove('overflow-hidden', 'h-screen')
      body?.classList.remove('overflow-hidden', 'h-screen')
    }
  }, [condition])
}
