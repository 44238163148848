import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { cookieStorage, createStorage } from 'wagmi'
import { superfluidRpcUrls, supportedChains } from './config/networks'

// Get projectId from https://cloud.walletconnect.com
export const projectId = process.env.NEXT_PUBLIC_PROJECT_ID

if (!projectId) throw new Error('Project ID is not defined')

const metadata = {
  name: 'SuperBoring',
  description:
    'Buy the bottom of the market without being smart enough to time it. The most powerful, truly onchain DCA.',
  url: 'https://app.superboring.finance', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

export const config = defaultWagmiConfig({
  chains: supportedChains,
  metadata,
  projectId,
  transports: superfluidRpcUrls,
  ssr: true,
  storage: createStorage({
    storage: cookieStorage
  })
})
