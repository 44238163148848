"use client";

import { useTranslation } from "@/app/i18n/client";
import { cn } from "@/utils";
import Image from "next/image";
import { FC } from "react";

export const PoweredBySuperfluid: FC<{ className?: string }> = ({
    className,
}) => {
    return (
        <a
            href="https://superfluid.finance"
            target="_blank"
            rel="noreferrer noopener"
        >
            <button
                data-testid="footer-superfluid-button"
                type="button"
                className={cn(
                    "flex gap-2 justify-center items-center px-6 py-2.5 border border-brand-main rounded-xl bg-black text-white text-sm",
                    className
                )}
            >
                <p className="font-semibold">Powered by</p>
                <Image
                    className="hue-rotate-180 invert"
                    src="/superfluid-logo.svg"
                    alt="Superfluid"
                    width={100}
                    height={24}
                />
            </button>
        </a>
    );
};
