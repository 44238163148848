import { forwardRef } from 'react'
import { Button, ButtonProps, buttonVariants } from './ui/button'
import shortenHex, { cn } from '@/utils'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount } from 'wagmi'
import { useTranslation } from 'react-i18next'
import { Icons } from './ui/icons'

export const ConnectButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, children, ...props }, ref) => {
    const { t } = useTranslation('common')
    const { address: user } = useAccount()
    const { open } = useWeb3Modal()

    return (
      <Button
        data-testid='connect-wallet'
        {...props}
        onClick={(evt) => {
          props.onClick?.(evt)
          open()
        }}
        className={cn(
          buttonVariants({ variant, size, className }),
          'flex gap-1'
        )}
      >
        <p>{user ? shortenHex(user) : children}</p>
        {user && <Icons.chevronDown size={16} />}
      </Button>
    )
  }
)
