import { useMemo } from 'react'
import { useAllTorexes } from './useAllTorexes'
import { useTokenList } from './useTokenList'
import uniqBy from 'lodash/uniqBy'
import { SuperTokenInfo } from '@superfluid-finance/tokenlist'
import { Address } from 'viem'

type UseTorexTokenParams = {
  selectedInboundToken?: SuperTokenInfo
  selectedOutboundToken?: SuperTokenInfo
}

export const useTorexTokens = ({
  selectedInboundToken,
  selectedOutboundToken
}: UseTorexTokenParams = {}) => {
  const { torexes, getTorexByTokens } = useAllTorexes()
  const { superTokens } = useTokenList()

  const tokenMap = useMemo(
    () =>
      Object.values(torexes).reduce(
        (acc, { inboundToken, outboundToken }) => {
          if (!acc.in.includes(inboundToken.address.toLowerCase() as Address)) {
            acc.in.push(inboundToken.address.toLowerCase() as Address)
          }

          if (
            !acc.out.includes(outboundToken.address.toLowerCase() as Address)
          ) {
            acc.out.push(outboundToken.address.toLowerCase() as Address)
          }

          return acc
        },
        { in: [], out: [] } as Record<'in' | 'out', Address[]>
      ),
    [torexes]
  )

  // InboundTokens:
  // 1. filter supertokens by possible inboundToken addresses
  const tokens = useMemo(() => {
    const inboundTokens = superTokens.filter(({ address }) =>
      tokenMap.in.includes(address.toLowerCase() as Address)
    )

    const outboundTokens = superTokens.filter(({ address }) =>
      tokenMap.out.includes(address.toLowerCase() as Address)
    )

    const tokens = uniqBy(
      inboundTokens.concat(outboundTokens),
      ({ address }) => address
    )

    if (selectedInboundToken && !selectedOutboundToken) {
      return {
        tokens,
        inboundTokens,
        outboundTokens: outboundTokens.filter(({ address }) =>
          getTorexByTokens(
            selectedInboundToken.address as Address,
            address as Address
          )
        )
      }
    } else if (!selectedInboundToken && selectedOutboundToken) {
      return {
        tokens,
        inboundTokens: inboundTokens.filter(({ address }) =>
          getTorexByTokens(
            address as Address,
            selectedOutboundToken.address as Address
          )
        ),
        outboundTokens
      }
    }

    return { tokens, inboundTokens, outboundTokens }
  }, [
    tokenMap,
    superTokens,
    selectedInboundToken,
    selectedOutboundToken,
    getTorexByTokens
  ])

  return tokens
}
