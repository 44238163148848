'use client'

import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'
import { useAccount } from 'wagmi'

function SentryContext() {
  const { address } = useAccount()

  useEffect(() => {
    if (address) {
      Sentry.setUser({ id: address })
    }
  }, [address])

  return null
}

export default SentryContext
