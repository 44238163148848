import { supportedChains } from '@/config/networks'
import { TorexRegistry } from '@/types'
import { ReactNode, createContext } from 'react'

const defaultValue = supportedChains.reduce(
  (acc, chain) =>
    Object.assign(acc, {
      [chain.id]: {}
    }),
  {} as TorexRegistry
)

export const TorexRegistryContext = createContext<TorexRegistry>(defaultValue)

export const TorexRegistryProvider: React.FC<{
  children: ReactNode
  torexRegistry: TorexRegistry
}> = ({ children, torexRegistry }) => {
  return (
    <TorexRegistryContext.Provider value={torexRegistry}>
      {children}
    </TorexRegistryContext.Provider>
  )
}
