import { useMemo } from 'react'
import * as chains from 'viem/chains'
import { useAccount } from 'wagmi'

export const useConnectorChain = () => {
  const { chainId } = useAccount()

  const matchedChain = useMemo(
    () => Object.values(chains).find((c) => c.id === chainId),
    [chainId]
  )

  return matchedChain
}
