import { NavKey, nav } from '@/constants'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'
import bg from '../../public/background.svg'

export const useCompositeBackground = () => {
  const activePath = usePathname()

  useEffect(() => {
    const dynBg = document.querySelector<HTMLDivElement>('#dynamic-background')
    const activeMenu = nav[activePath.replace('/en', '') as NavKey]

    if (dynBg) {
      dynBg.style.scale = '1.15'
      dynBg.style.backgroundImage = `url(${activeMenu?.image ?? bg.src})`
    }
  }, [activePath])
}
