"use client";

import Image from "next/image";
import { Icons } from "./ui/icons";
import { PoweredBySuperfluid } from "./PoweredBySuperfluid";
import { HSeparator } from "./Separator";
import { usePathname } from "next/navigation";
import { cn } from "@/utils";
import { nav } from "@/constants";
import { useMemo } from "react";
import Link from "next/link";
import { useTranslation } from "@/app/i18n/client";

export const Footer = () => {
    const activePath = usePathname();
    const { i18n } = useTranslation("footer");

    const isLanding = useMemo(
        () => !Object.keys(nav).some((navItem) => activePath.includes(navItem)),
        [activePath]
    );

    return (
        <footer
            className={cn(
                "w-full mt-auto relative bottom-0 h-[90px] bg-black md:max-8xl:fixed",
                isLanding && "!relative"
            )}
        >
            <HSeparator className="w-full absolute top-0" />
            <div data-testid="footer" className="flex justify-between items-center gap-8 px-8 py-7 max-sm:flex-col">
                <div className="flex gap-14">
                    <Image
                        src="/superboring_logo.svg"
                        alt="SuperBoring"
                        width={82}
                        height={36}
                    />
                    <div className="flex justify-between items-center">
                        <div className="flex gap-6">
                            <a
                                data-testid="footer-x-button"
                                className="footer-twitter-button"
                                href="https://twitter.com/SuperBoring_xyz"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Icons.social.twitter size={20} />
                            </a>

                            <a
                                data-testid="footer-wrapcast-button"
                                className="footer-twitter-button"
                                href="https://warpcast.com/superboring"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Icons.social.farcaster size={20} />
                            </a>

                            {/* <a
                className='footer-github-button'
                href='https://github.com/superfluid-finance/averageX'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icons.github size={20} />
              </a> */}
                            <a
                                data-testid="footer-docs-button"
                                className="footer-docs-button"
                                href="https://docs.superboring.xyz/docs/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Icons.book size={20} />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="flex items-center">
                    <div className="flex items-center gap-3">
                        <Link
                            href={`/${i18n.language}/terms`}
                            className="text-sm font-medium text-brand-main underline"
                        >
                            Terms & Conditions
                        </Link>
                        <Link
                            href={`/${i18n.language}/privacy`}
                            className="text-sm font-medium text-brand-main underline"
                        >
                            Privacy Policy
                        </Link>
                    </div>
                    <PoweredBySuperfluid className="border-gray-400 ml-5 max-lg:hidden" />
                </div>
            </div>
        </footer>
    );
};
