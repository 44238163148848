import { cn } from '@/utils'
import { FC } from 'react'

export const VSeparator: FC<{ className?: string }> = ({ className }) => (
  <div
    className={cn(
      'flex-grow-0 flex-shrink-0 w-[0.5px] min-h-full bg-gradient-to-b from-gray-600 via-white to-gray-600 from-20% via-50% to-80%',
      className
    )}
  />
)

export const HSeparator: FC<{ className?: string }> = ({ className }) => (
  <div
    className={cn(
      'w-full h-px bg-gradient-to-r from-gray-600 via-white to-gray-600 from-20% via-50% to-80%',
      className
    )}
  />
)
