'use client'

import { FC, useEffect } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { analytics } from '@/utils/analytics'
import { useAccount, useAccountEffect } from 'wagmi'
import { usePrevious } from 'react-use'

export const Analytics: FC = () => {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  const { address: user, chain } = useAccount()

  const prev = usePrevious({ pathname, searchParams, user })

  useAccountEffect({
    onConnect: () => {
      analytics.track('WALLET_CONNECTED', { user, chain })
    },
    onDisconnect: () => {
      analytics.track('WALLET_DISCONNECTED', { user })
    }
  })

  useEffect(() => {
    if (user && user !== prev?.user) {
      analytics.identify(user)
    }
  }, [user, prev?.user])

  useEffect(() => {
    if (pathname !== prev?.pathname || searchParams !== prev?.searchParams) {
      const url = `${pathname}?${searchParams}`
      analytics.page({ url })
    }
  }, [pathname, searchParams, prev?.pathname, prev?.searchParams])

  return null
}
